@font-face {
    font-family: 'GT Eesti Pro Display';
    src: local('GT Eesti Pro Display Bold'), local('GTEestiProDisplay-Bold'),
        url('/assets/fonts/GTEestiProDisplay-Bold.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/GTEestiProDisplay-Bold.woff2') format('woff2'),
        url('/assets/fonts/GTEestiProDisplay-Bold.woff') format('woff'),
        url('/assets/fonts/GTEestiProDisplay-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT Eesti Pro Display';
    src: local('GT Eesti Pro Display Light'), local('GTEestiProDisplay-Light'),
        url('/assets/fonts/GTEestiProDisplay-Light.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/GTEestiProDisplay-Light.woff2') format('woff2'),
        url('/assets/fonts/GTEestiProDisplay-Light.woff') format('woff'),
        url('/assets/fonts/GTEestiProDisplay-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT Eesti Pro Display';
    src: local('GT Eesti Pro Display UltraBold'), local('GTEestiProDisplay-UltraBold'),
        url('/assets/fonts/GTEestiProDisplay-UltraBold.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/GTEestiProDisplay-UltraBold.woff2') format('woff2'),
        url('/assets/fonts/GTEestiProDisplay-UltraBold.woff') format('woff'),
        url('/assets/fonts/GTEestiProDisplay-UltraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT Eesti Pro Display';
    src: local('GT Eesti Pro Display Medium'), local('GTEestiProDisplay-Medium'),
        url('/assets/fonts/GTEestiProDisplay-Medium.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/GTEestiProDisplay-Medium.woff2') format('woff2'),
        url('/assets/fonts/GTEestiProDisplay-Medium.woff') format('woff'),
        url('/assets/fonts/GTEestiProDisplay-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT Eesti Pro Display';
    src: local('GT Eesti Pro Display UltraLight'), local('GTEestiProDisplay-UltraLight'),
        url('/assets/fonts/GTEestiProDisplay-UltraLight.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/GTEestiProDisplay-UltraLight.woff2') format('woff2'),
        url('/assets/fonts/GTEestiProDisplay-UltraLight.woff') format('woff'),
        url('/assets/fonts/GTEestiProDisplay-UltraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'GT Eesti Pro Display';
    src: local('GT Eesti Pro Display Regular'), local('GTEestiProDisplay-Regular'),
        url('/assets/fonts/GTEestiProDisplay-Regular.eot?#iefix') format('embedded-opentype'),
        url('/assets/fonts/GTEestiProDisplay-Regular.woff2') format('woff2'),
        url('/assets/fonts/GTEestiProDisplay-Regular.woff') format('woff'),
        url('/assets/fonts/GTEestiProDisplay-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'NotoSans';
    src: local('GT Eesti Pro Display Bold'), local('GTEestiProDisplay-Bold'), 
        url('/assets/fonts/NotoSans-VariableFont_wdth,wght.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


.GTEPD {
    font-family: 'GT Eesti Pro Display';
}